.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a.active, .custom-dot,.icons-a-tag, 
.nav-pills .nav-link
{
  color: rgb(34 34 134) !important;
  font-weight: 500;
}

nav {
  font-weight: 500;
  font-size: 1.2rem;
}

.project-section .card-title {
  font-size: 24px;
  font-weight: 700;
}

.project-section {
  text-align: left;
}

.project-section .card {
  border: none;
}

.project-section .card-text {
  font-size: 18px;
  font-weight: 400;
}



.project-section .col-sm-5,
.project-section .col-sm-7 {
  padding: 0;
}

.experience-section{
  .nav .nav-item button.active {
    background-color: transparent;
    color: black !important;
  }
  
  
  .nav .nav-item button.active::after {
    content: "";
    border-left: 3px solid rgb(34 34 134);
    height: 100%;
    position: absolute;
    left: -3px;
    top: 0;
    border-radius: 5px 0 0 5px;
  }
  
}

.experience-section ul {
  min-width: max-content;
}

.experience-section ul li {
  padding: 0.5rem 0.5rem 0.5rem 0;
  
}


.skills-section ul li{
  list-style-type: none;
  font-weight: 400;
}

.skills-section ul {
  padding: 0;
}

.footer-section .nav-link{
  display: inline;
}


.footer-section li {
  display: inline;
  padding: 0.5rem;
  font-size: 18px;
  font-weight: 500;
}

.footer-section ul {
  display: inline-block;
}

.navbar {
  background-color: transparent !important;
  padding: 1.5rem 0;
}
@media screen and (max-width:1024px) {
  .hero-text {
    font-size: 48px;
    line-height:1em;  
  }
  .hero-img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
  .padding-50 {
    padding: 16px;
  }
  
  .padding-sides-50 {
    padding: 0 16px;
  } 
  .hero-section {
    height: auto;
    text-align: center;
  }
  .experience-section h1 {
    font-size:56px;
    line-height:40px;
  } 
  .experience-section .tab-pane {
    text-align: left;
    height: auto;
  }

  .footer-section > *{
    text-align: center;
  }
  .experience-section-div {
    flex-wrap: wrap;
  }
  #design .col-sm-4 iframe{
    width: 100%;
    height: auto;
  }
  .getintouch {
    justify-content: center;
  }
  #navbarText {
    text-align: center;
  }
  .footer-section {
    justify-content: center;
    padding: 16px 0 0 0;
  }
  .footer-section .col-sm-6 {
    text-align: center !important;
  }
  .skills-section .col-sm-3 {
    width: 50%;
}
  
}
@media screen and (min-width:1024px) {
  #projects img {
    height: 300px;
    width: auto;
  }
  .hero-text {
    font-size: 90px;
    line-height:'1em';  
  }
  .hero-img {
    height: 400px;
  }
  .padding-50 {
    padding: 40px;
  }
  
  .padding-sides-50 {
    padding: 0 40px;
  } 
  .hero-section {
    height: 600px;
    text-align: left;
  }
  .experience-section h1 {
    font-size:56px;
    line-height:40px,
  }
  .experience-section .tab-pane {
    text-align: left;
  }
  .footer-section > *{
    text-align: left;
  }
  
    
}


#design .col-sm-4 iframe{
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 8px;
  margin: 0.5rem 0.15rem;
}




